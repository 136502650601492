import {
  Container,
  Typography,
  Button,
  Box,
  ListItemButton,
  Stack,
  Checkbox,
} from "@mui/material";
import Api from "../../api";
import Grid from "@mui/material/Grid2";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import { useLoaderData, useNavigate } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Brightness1Icon from "@mui/icons-material/Brightness1";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import Badge from "@mui/material/Badge";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { useRevalidator } from "react-router";

import dayjs from "dayjs";
import { useContext, useEffect, useState } from "react";
import UserContext from "../../context/userContext";
const path = "/admin_panel/";

export async function adminPanelLoader() {
  return Api.get(path);
}

interface ICompanyOverview {
  id: number;
  name: string;
  all_sites_operative: boolean;
  num_incidents: number;
  sites: [
    {
      name: string;
      operative: boolean;
      incidents: any[];
    }
  ];
}

export default function AdminPanelPage() {
  const data: any = useLoaderData() as any;
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const revalidator = useRevalidator();
    const {
      setSnackbarText,
    } = useContext(UserContext);

  async function goToDashboard(companyId: number) {
    try {
      await Api.post(path, { company: companyId, type: "dashbord" });
    } catch (e) {
      return;
    }
    navigate("/");
  }

  useEffect(() => {
    const interval = setInterval(() => {
      revalidator.revalidate();
    }, 20000);

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);

  async function closeIncident(incidentId: number) {
    try {
      await Api.post(path, { incident: incidentId, type: "close_incident" });
    } catch (e) {
      return;
    }
    revalidator.revalidate();
  }

    async function updateAlertSetting(
      alert_id: number | undefined,
      site_id: string,
      channel: string,
    ) {
      const data = {
        channel: channel,
        type:"update_alert",
        alert:alert_id,
        site: site_id,
      };
      setIsLoading(true);
      try {
        const res = await Api.post(path, data);        
        setSnackbarText("Saved settings");
      } catch {}
      setIsLoading(false);

      revalidator.revalidate();
    }

  return (
    <Box sx={{ padding: "2rem" }}>
      <Grid container spacing={2}>
        <Grid size={12}>
          <Typography variant="h5" sx={{ mb: "1rem" }}>
            Company
          </Typography>
          <Grid container spacing={1}>
            {data.companies?.map((company: ICompanyOverview) => {
              return (
                <Grid size={3}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Grid
                        container
                        justifyContent={"space-between"}
                        sx={{ width: "100%" }}
                      >
                        <Grid>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                          >
                            <Brightness1Icon
                              sx={{
                                color: company.all_sites_operative
                                  ? "green"
                                  : "red",
                                mr: "1rem",
                              }}
                            />
                            <Typography
                              component="span"
                              sx={{ marginTop: "0" }}
                            >
                              {company.name} <b>({company.sites.length})</b>
                            </Typography>{" "}
                          </Stack>
                        </Grid>
                        <Grid size={1}>
                          {company.num_incidents > 0 && (
                            <Badge
                              badgeContent={company.num_incidents}
                              color="primary"
                            >
                              <NotificationsActiveIcon color="action" />
                            </Badge>
                          )}
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Button
                        onClick={() => goToDashboard(company.id)}
                        endIcon={<KeyboardArrowRightIcon />}
                      >
                        Go to dashboard
                      </Button>



                      <List>
                        {company.sites.map((site) => (
                          <>
                            <ListItem>
                              <ListItemIcon>
                                <Brightness1Icon
                                  sx={{
                                    color: site.operative ? "green" : "red",
                                    width: "1rem",
                                  }}
                                />
                              </ListItemIcon>
                              <ListItemText
                                primary={site.name}
                                sx={{ padding: "0" }}
                              />
                            </ListItem>
                            {site.incidents.map((incident: any) => {
                              return (
                                <ListItem>
                                  <ListItemIcon>
                                    <SubdirectoryArrowRightIcon />
                                  </ListItemIcon>
                                  <ListItemIcon>
                                    <NotificationsActiveIcon />
                                  </ListItemIcon>
                                  <ListItemText
                                    primary={`Start: ${dayjs(
                                      incident.start
                                    ).format("HH:mm DD.MM")}`}
                                  />
                                  <ListItemText
                                    primary={`End: ${dayjs(incident.end).format(
                                      "HH:mm DD.MM"
                                    )}`}
                                  />
                                  <ListItemButton
                                    disabled={incident.end === undefined}
                                    onClick={() => closeIncident(incident.id)}
                                  >
                                    Close
                                  </ListItemButton>
                                </ListItem>
                              );
                            })}

                          </>
                        ))}
                      </List>

                      <Typography sx={{mt:"1rem"}}>Mine Varsler</Typography>
                      <Table  size="small" aria-label="a dense table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Site</TableCell>
                            <TableCell>SMS</TableCell>
                            <TableCell>EMAIL</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {company.sites.map((row:any) => (
                            <TableRow
                              key={row.name}>
                              <TableCell>{row.name}</TableCell>
                              <TableCell>
                                <Checkbox
                                  checked={row.user_sms_alert}
                                  disabled={isLoading}
                                  onClick={() =>
                                    updateAlertSetting(row.user_sms_alert, row.id, "SMS")
                                  }
                                />
                              </TableCell>
                              <TableCell>
                                <Checkbox
                                  checked={row.user_email_alert}
                                  disabled={isLoading}
                                  onClick={() =>
                                    updateAlertSetting(row.user_email_alert, row.id, "EMAIL")
                                  }
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid size={3}>
          {false &&
            data?.incidents.map((incident: any) => (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Brightness1Icon sx={{ mr: "1rem" }} />
                  <Typography component="span">
                    {dayjs(incident.created).format("HH:mm DD.MM")} -{" "}
                    {incident.site.name}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {incident.sent_alerts.map((alert: any) => (
                    <p>
                      {dayjs(alert.created).format("HH:mm DD.MM")} -{" "}
                      {alert.type}
                    </p>
                  ))}
                </AccordionDetails>
              </Accordion>
            ))}
        </Grid>
      </Grid>
    </Box>
  );
}
