import { Container, Grid, Typography, Button } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import SiteContext from "../../context/siteContext";
import { useContext, useState } from "react";
import { IUniFyAP, IUnifyClient } from "../../interface";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import {
  greenStatusColor,
  redStatusColor,
} from "../../pages/dashboard/diagram_config";
import dayjs, { Dayjs } from "dayjs";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import DevicesIcon from "@mui/icons-material/Devices";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import duration from "dayjs/plugin/duration";
import { Stack } from "@mui/material";
dayjs.extend(duration);

function bytesToMbits(bytes: number) {
  const bitsPerSecond = (bytes * 8);
  const kbitsPerSecond = bitsPerSecond / 1_000;
  const mbitsPerSecond = bitsPerSecond / 1_000_000;
  const gbitsPerSecond = bitsPerSecond / 1_000_000_000;

  if (gbitsPerSecond > 1) {
    return gbitsPerSecond.toFixed(2) + " Gbps";
  }
  if (mbitsPerSecond > 1) {
    return mbitsPerSecond.toFixed(2) + " Mbps";
  }
  if (kbitsPerSecond > 1){
    return kbitsPerSecond.toFixed(2) + " Kbps";
  }
  return bitsPerSecond.toFixed(2) + " bps";

}

function bytesToMB(bytes:number) {
  return Math.round(bytes / (1024 ** 2));
}

function secondsToHours(seconds: number) {
  return dayjs.duration(seconds, "seconds").format("D[d] H[h] mm[m] ss[s]");
}

export default function LocationWifi() {
  const { dashboardData } = useContext(SiteContext);
  const [activeTab, setActiveTab] = useState(0);
  const url = dashboardData?.location?.wifi_admin_url;


  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <Container maxWidth="xl" component={Paper} sx={{ paddingTop: "1rem" }}>
      <Box
        sx={{ borderBottom: 1, borderColor: "divider", marginBottom: "1rem" }}
      >
        <Tabs value={activeTab} onChange={handleChange}>
          <Tab label="Access Point" />
          <Tab label="Clients" />
        </Tabs>
        {activeTab === 0 && <AccessPointsTable />}
        {activeTab === 1 && <ClientsTable />}
      </Box>
    </Container>
  );
}

function AccessPointsTable() {
  const { dashboardData } = useContext(SiteContext);
  const unify_ap_update = dashboardData?.unify_aps_update;

  return (
    <Box sx={{mt:"1rem"}}>
      {unify_ap_update && (
        <Typography variant="body2">
          Last update: {dayjs(unify_ap_update).format("HH:mm DD.MM.YYYY ")}
        </Typography>
      )}
      <TableContainer sx={{ mt: "0rem" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Status</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>IP</TableCell>
              <TableCell>Uptime</TableCell>
              <TableCell>Connected</TableCell>
              <TableCell>Upload</TableCell>
              <TableCell>Download</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dashboardData?.unify_aps.map((row: IUniFyAP) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell key={"status"}>
                  <RadioButtonCheckedIcon
                    sx={{
                      color:
                        row.state === 1 ? greenStatusColor : redStatusColor,
                    }}
                  />
                </TableCell>

                <TableCell>{row.name}</TableCell>
                <TableCell>{row.ip}</TableCell>
                <TableCell>{secondsToHours(row.uptime)}</TableCell>
                <TableCell>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <DevicesIcon />
                    <Typography variant="body2">{row.connected}</Typography>
                  </Stack>
                </TableCell>
                <TableCell>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <ArrowUpwardIcon
                      sx={{
                        color: "blue",
                      }}
                    />
                    <Typography variant="body2">
                      {bytesToMbits(row.upload_speed_bytes_per_second)} 
                    </Typography>
                  </Stack>
                </TableCell>
                <TableCell>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <ArrowDownwardIcon
                      sx={{
                        color: "orange",
                      }}
                    />
                    <Typography variant="body2">
                      {bytesToMbits(row.download_speed_bytes_per_second)}
                    </Typography>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

function ClientsTable() {
  const { dashboardData } = useContext(SiteContext);
  const update = dashboardData?.unify_clients_update;

  return (
    <Box sx={{mt:"1rem"}}>
      {update && (
        <Typography variant="body2">
          Last update: {dayjs(update).format("HH:mm DD.MM.YYYY ")}
        </Typography>
      )}
      <TableContainer sx={{ mt: "0rem" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Status</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Connection</TableCell>
              <TableCell>IP</TableCell>
              <TableCell>MAC</TableCell>
              <TableCell>Uptime</TableCell>
              <TableCell>Usage 24h</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dashboardData?.unify_clients.map((row: IUnifyClient) => (
              <TableRow
                key={row.mac}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell key={"status"}>
                  <RadioButtonCheckedIcon
                    sx={{
                      color:
                        row.status === "online" ? greenStatusColor : redStatusColor,
                    }}
                  />
                </TableCell>
                <TableCell>{row.display_name}</TableCell>
                <TableCell>{row.last_uplink_name}</TableCell>
                <TableCell>{row.ip}</TableCell>
                <TableCell>{row.mac}</TableCell>
                <TableCell>{secondsToHours(row.uptime)}</TableCell>
                <TableCell>{bytesToMB(row.usage_bytes)} MB</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}