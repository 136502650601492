import {
  Container,
  Grid,
  Button,
  Box,
  Typography,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import style from "./Header.module.css";
import UserContext from "../context/userContext";
import { useContext } from "react";
import SiteContext from "../context/siteContext";

import Api from "../api";

export default function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const { userData, setUserData } = useContext(UserContext);
  const { dashboardData } = useContext(SiteContext);

  async function handleLogout(){
    try{
      const res = await Api.delete("/auth")
      setUserData();
      window.location.replace("/account/login/");
    }catch(e) {}
  }
  return (
    <div className={style.wrapper}>
      <Box display="flex">
        <Box width="12rem" sx={{
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',    
            height: '4rem',   

          }}>

          <Link to="/">
            <img
              className={style.logo}
              src={userData?.company?.logo}
              width={"100%"}
              style={{ maxWidth: "10rem", maxHeight:"3rem" }}
              alt={"logo"}
            />
          </Link>
        </Box>
        <Box flexGrow={1} className={style.layout_content}>
          <Container maxWidth="xl" sx={{marginTop:"1rem"}}>
            <Grid
              container
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Grid item>
                <div className={style.logoContainer}>
                  <Box>
                    
                    <Typography variant="h6" sx={{ margin: 0 }}>
                      {dashboardData?.location?.name}
                    </Typography>
                  </Box>
                </div>
              </Grid>

              <Grid item md={5}>
                <Grid container spacing={5} justifyContent={"end"}>
                  {userData?.is_superuser && <Grid item>
                    <Link to="/admin_panel">
                      <Button
                        
                      >
                        Admin Panel
                      </Button>
                    </Link>
                  </Grid>}
                  <Grid item>
                    <Link to="/">
                      <Button
                        
                      >
                        Dashboard
                      </Button>
                    </Link>
                  </Grid>

                  {false && <Grid item>
                    <Link to="/new">
                      <Button
                        color={
                          pathname.endsWith("order") ? "primary" : "inherit"
                        }
                      >
                        New Site
                      </Button>
                    </Link>
                  </Grid>}
                  <Grid item>
                    <Link to="/">
                      <Button onClick={handleLogout}>Log out</Button>
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </div>
  );
}
