import { useContext, useEffect, useState } from "react";

import {
  Button,
  TextField,
  Checkbox,
  FormControl,
  Modal,
  Box,
  Typography,
  Container,
  Stack,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import style from "../Site.module.css";
import SiteContext from "../../../context/siteContext";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import Api from "../../../api";
import _ from "lodash";
import { useParams, useRevalidator } from "react-router-dom";
import UserContext from "../../../context/userContext";
import DeleteIcon from "@mui/icons-material/Delete";

const textB = {
  width: "100%",
  margin: "0.5rem 0 0.5rem 0",
};

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function EditUserModal(props: any) {
  const { show, setShowModal, alerts, user, setUser } = props;
  const { setIsLoading } = useContext(UserContext);
  const revalidator = useRevalidator();
  const [errorFeedback, setErrorFeedback] = useState<any>({});

  async function createUser() {
    let url = "/users/";
    setIsLoading(true);
    try {
      if (user.id) {
        await Api.put(`${url}${user.id}/`, user);
      } else {
        await Api.post(url, user);
      }
      setShowModal(false);
      setUser(empty_person);
      revalidator.revalidate();
    } catch (e:any) {
      if (e.status === 400) {
          setErrorFeedback(e.data);

      }
  }
    setIsLoading(false);
  }

  function handleChange(e: any) {
    const cp = { ...user };
    cp[e.target.name] = e.target.value;
    setUser(cp);
    setErrorFeedback({});
  }

  function checkFormError(field: string) {
    return field in errorFeedback;
  }

  function getFormError(field: string) {
    if (field in errorFeedback) {
      return errorFeedback[field];
    }
  }

  return (
    <Modal
      open={show}
      onClose={() => {
        setShowModal(false);
        setUser(empty_person);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <h2 style={{ margin: "1rem 0 2rem 0" }}>Edit User</h2>
        <FormControl variant="standard" style={textB} onChange={handleChange}>
          <TextField
            name="first_name"
            variant="standard"
            label="Firstname"
            error={checkFormError("first_name")}
            helperText={getFormError("first_name")}
            style={textB}
            value={user.first_name}
          ></TextField>
          <TextField
            name="last_name"
            variant="standard"
            label="Lastname"
            error={checkFormError("last_name")}
            helperText={getFormError("last_name")}
            style={textB}
            value={user.last_name}
          ></TextField>
          <TextField
            disabled={user.id !== ""}
            name="email"
            variant="standard"
            label="Email"
            error={checkFormError("username")}
            helperText={getFormError("username")}
            style={textB}
            value={user.email}
          />
          <TextField
            name="phone_number"
            variant="standard"
            label="Phone"
            error={checkFormError("phone_number")}
            helperText={getFormError("phone_number")}
            style={textB}
            value={user.phone_number}
          />
        </FormControl>

        <Button variant="contained" style={textB} onClick={createUser}>
          Save
        </Button>
      </Box>
    </Modal>
  );
}

const emptyTeamsForm = {
  teams_hook: "",
};
function EditTeamsAlertModal(props: any) {
  const params = useParams();

  const { show, setShowModal } = props;
  const [teamsForm, setTeamsForm] = useState(emptyTeamsForm);
  const { setIsLoading } = useContext(UserContext);
  const revalidator = useRevalidator();

  useEffect(() => {
    setTeamsForm(emptyTeamsForm);
  }, [show]);

  async function save() {
    const data: any = { ...teamsForm };
    data.channel = "TEAMS";
    try {
      await Api.post(`/sites/${params.id}/alerts/`, data);
    } catch (e) {}
    revalidator.revalidate();
    setIsLoading(false);
    setShowModal(false);
  }

  function handleChange(e: any) {
    const cp = { ...teamsForm };
    const name: "teams_hook" = e.target.name;
    cp[name] = e.target.value;
    setTeamsForm(cp);
  }

  return (
    <Modal
      open={show}
      onClose={() => {
        setShowModal(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <h2 style={{ margin: "1rem 0 2rem 0" }}>Create teams hook</h2>
        <FormControl variant="standard" style={textB} onChange={handleChange}>
          <TextField
            name="teams_hook"
            variant="standard"
            label="Teams Hook"
            style={textB}
            value={teamsForm.teams_hook}
            multiline
          />
        </FormControl>
        <Button variant="contained" style={textB} onClick={save}>
          Save
        </Button>
      </Box>
    </Modal>
  );
}

const empty_person = {
  id: "",
  email: "",
  phone_number: "",
  first_name: "",
  last_name: "",
};
export default function AlertsPage() {
  const { dashboardData } = useContext(SiteContext);
  const { setIsLoading, setSnackbarText } = useContext(UserContext);

  const params = useParams();
  const revalidator = useRevalidator();

  const { alerts } = dashboardData ?? {};
  const [showModal, setShowModal] = useState(false);
  const [showTeamsModal, setShowTeamsModal] = useState(false);
  const [user, setUser] = useState(empty_person);

  async function appointResponsible(user: any) {
    setIsLoading(true);
    try {
      const res = await Api.post(`/sites/${params.id}/appoint/${user.id}/`);
    } catch {}
    revalidator.revalidate();
    setIsLoading(false);
  }

  async function updateAlertSetting(
    alert_id: string | undefined,
    user: any,
    channel: string
  ) {
    const data = {
      user_id: user.id,
      channel: channel,
    };
    const url = `/sites/${params.id}/alerts/`;
    setIsLoading(true);
    try {
      
      if (alert_id) {
        const res = await Api.delete(url, {...data, alert_id:alert_id});
      } else {
        const res = await Api.post(url, data);
      }
      
      setSnackbarText("Saved settings");
    } catch {}
    revalidator.revalidate();
    setIsLoading(false);
  }

  async function deleteTeamsAlert(id: string) {
    const url = `/sites/${params.id}/alerts/`;
    setIsLoading(true);
    try {
      const res = await Api.delete(url, {
        alert_id: id,
      });
    }catch (e) {}
    revalidator.revalidate();
    setIsLoading(false);
  }

  async function runTestConnection(alert_id: string) {
    setSnackbarText("Sending an alert");

    const url = `/sites/${params.id}/alerts/?alert=${alert_id}`;
    try {
      await Api.get(url);
    } catch (e) {
      setSnackbarText("Something went wrong");
    }
  }

  return (
    <Container maxWidth="xl">
      <EditUserModal
        show={showModal}
        setShowModal={setShowModal}
        alerts={alerts}
        user={user}
        setUser={setUser}
      />
      <EditTeamsAlertModal
        show={showTeamsModal}
        setShowModal={setShowTeamsModal}
      />

      <div className={style.tile}>
        <Typography variant="h6">Downtime Alert</Typography>
        <p>Send alerts to chosen channel if site goes down</p>
        <Grid container spacing={2} justifyContent={"space-between"} sx={{marginTop:"2rem"}}>
          <Grid>
            <Typography variant="h6">Contact</Typography>
          </Grid>
          <Grid>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => setShowModal(true)}
            >
              Add Contact
            </Button>
          </Grid>
        </Grid>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Responsible</TableCell>
                <TableCell>SMS Alert</TableCell>
                <TableCell>Email Alert</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dashboardData?.users_with_alerts?.map((user: any) => {
                return (
                  <TableRow key={user.id}>
                    <TableCell>
                      {user.first_name + " " + user.last_name}
                    </TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.phone_number}</TableCell>
                    <TableCell>
                      {_.find(dashboardData.location?.contact_person, {
                        id: user.id,
                      }) != null ? (
                        <CheckIcon />
                      ) : (
                        <Button onClick={() => appointResponsible(user)}>
                          Appoint
                        </Button>
                      )}
                    </TableCell>
                    <TableCell align="left">
                      <Checkbox
                        checked={user.sms_alert}
                        onClick={() =>
                          updateAlertSetting(user.sms_alert, user, "SMS")
                        }
                      />
                      <Button
                        size="small"
                        disabled={!user.sms_alert}
                        onClick={() => runTestConnection(user.sms_alert)}
                      >
                        TEST
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        checked={user.email_alert}
                        onClick={() =>
                          updateAlertSetting(user.email_alert, user, "EMAIL")
                        }
                      />
                      <Button
                        size="small"
                        disabled={!user.email_alert}
                        onClick={() => runTestConnection(user.email_alert)}
                      >
                        TEST
                      </Button>
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        color="primary"
                        startIcon={<EditIcon />}
                        onClick={() => {
                          setUser(user);
                          setShowModal(true);
                        }}
                      >
                        Edit
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Grid
          container
          spacing={2}
          justifyContent={"space-between"}
          sx={{ paddingTop: "2rem" }}
        >
          <Grid>
            <Typography variant="h6">Teams Alerts</Typography>
          </Grid>
          <Grid>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => setShowTeamsModal(true)}
            >
              Add teams link
            </Button>
          </Grid>
        </Grid>
        <TableContainer>
          <TableHead>
            <TableRow>
              <TableCell>Teams Hook</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dashboardData?.teams_alerts.map((alert: any) => {
              return (
                <TableRow key={alert.id}>
                  <TableCell>{alert.teams_hook}</TableCell>
                  <TableCell align="left">
                    <Button
                      color="primary"
                      onClick={() => runTestConnection(alert.id)}
                    >
                      Test
                    </Button>
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      color="error"
                      startIcon={<DeleteIcon />}
                      onClick={() => deleteTeamsAlert(alert.id)}
                    >
                      Remove
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </TableContainer>
      </div>
    </Container>
  );
}
